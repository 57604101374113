import React from 'react'
import Layout from '../components/layout'
import SlideShow from '../components/SlideShow'
import red from '../images/wlbg01.jpg'
import blue from '../images/wlbg02.jpg'
import green from '../images/wlbg03.jpg'
import '../css/styles.css'
class IndexPage extends React.Component {

  componentDidMount() {
    document.body.classList.add("is-preload")
    window.addEventListener('load', this.initialLoadAnimation);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.initialLoadAnimation);
  }

  initialLoadAnimation = () => {
    window.setTimeout(function() {
      document.body.classList.remove("is-preload")
    }, 100);
  }

  render() {

    return (
      <Layout>

          <header id="header">
            <h1>Ever Glows</h1>
            <p>Coming soon in Q4 2018.<br/>
            For now, enjoy views of Hawaii.</p>
          </header>

          {/* <form id="signup-form" method="post" action="#">
            <input type="email" name="email" id="email" placeholder="Email Address" />
            <input type="submit" value="Sign Up" />
          </form> */}

          <footer id="footer">
            <ul className="icons">
              <li><a href="https://facebook.com" className="fab fa-facebook fa-lg"></a></li>
              <li><a href="https://aws.amazon.com" className="fab fa-aws fa-lg"></a></li>
              <li><a href="https://duckduckgo.com" className="fas fa-search fa-lg"></a></li>
            </ul>
            <ul className="copyright">
              <li>&copy; Everglows.com</li><li>Template from <a href="http://html5up.net">HTML5 UP</a></li>
            </ul>
          </footer>
          <SlideShow
            slideInterval={3400}
            autoplay
            slides={[red, blue, green]}
          />
      </Layout>
    )
  }
}

export default IndexPage
